a {
  color: #8a2be2;
}
.container {
  overflow: auto;
  height: 100vh;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 12px;
}
.logo {
  width: 60px;
  height: 60px;
}
.header {
  font-size: 30px;
  font-weight: bold;
}

.input {
  padding: 1rem;
  border-radius: 12px;
  border: 1px lightgray solid;
  width: 15rem;
}
.votesContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 12px;
}
.votesContainer div {
  border-bottom: 1px rgb(231, 231, 231) solid;
  padding: 20px 0;
}
.votesContainer div b {
  color: rgb(85, 84, 84);
}

.statsHeader {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  margin-top: 2rem;
}

.shareBtn {
  border-radius: 10px;
  padding: 0.575rem 0.65rem 0.525rem;
  padding: 6px 12px !important;
  white-space: normal;
  text-align: center;
  font-size: 16px !important;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8a2be2;
  color: white;
  margin-left: 12px;
  text-decoration: none;
}
.shareBtn:hover {
  cursor: pointer;
}

@media (max-width: 767px) {
  .statsHeader {
    flex-direction: column;
  }
  .shareBtn {
    margin-top: 12px;
    margin-left: 0;
    width: 100%;
  }
  .inputcontainer {
    width: 100%;
  }
  .input {
    width: -webkit-fill-available;
  }
}
